import { createRef } from 'react';
import { CDN_URL } from './index';

export const BossEnum = {
  cleaver: 'cleaver',
  rocket: 'rocket',
  knight: 'knight',
  blaster: 'blaster',
  queen: 'queen',
};

export const VideosEnum = {
  idle: 'idle',
  hit: 'hit',
  death: 'death',
  recharge: 'recharge',
};

export const bossAssets = {
  [BossEnum.knight]: {
    [VideosEnum.idle]: {
      videoSrc: `${CDN_URL}/bosses/${BossEnum.knight}/${VideosEnum.idle}`,
      videoRef: createRef(),
    },
    [VideosEnum.hit]: {
      videoSrc: `${CDN_URL}/bosses/${BossEnum.knight}/${VideosEnum.hit}`,
      audioSrc: `${CDN_URL}/bosses/${BossEnum.knight}/${VideosEnum.hit}.mp3`,
      videoRef: createRef(),
      audioRef: createRef(),
    },
    [VideosEnum.death]: {
      videoSrc: `${CDN_URL}/bosses/${BossEnum.knight}/${VideosEnum.death}`,
      audioSrc: `${CDN_URL}/bosses/${BossEnum.knight}/${VideosEnum.death}.mp3`,
      videoRef: createRef(),
      audioRef: createRef(),
    },
    // [VideosEnum.recharge]: {
    //   videoSrc: `${CDN_URL}/bosses/${BossEnum.knight}/${VideosEnum.recharge}`,
    //   videoRef: createRef(),
    // },
    bg: `${CDN_URL}/bosses/${BossEnum.knight}/bg.png`,
    bgMobile: `${CDN_URL}/bosses/${BossEnum.knight}/bg-mobile.png`,
  },
  [BossEnum.rocket]: {
    [VideosEnum.idle]: {
      videoSrc: `${CDN_URL}/bosses/${BossEnum.rocket}/${VideosEnum.idle}`,
      videoRef: createRef(),
    },
    [VideosEnum.hit]: {
      videoSrc: `${CDN_URL}/bosses/${BossEnum.rocket}/${VideosEnum.hit}`,
      audioSrc: `${CDN_URL}/bosses/${BossEnum.rocket}/${VideosEnum.hit}.mp3`,
      videoRef: createRef(),
      audioRef: createRef(),
    },
    [VideosEnum.death]: {
      videoSrc: `${CDN_URL}/bosses/${BossEnum.rocket}/${VideosEnum.death}`,
      audioSrc: `${CDN_URL}/bosses/${BossEnum.rocket}/${VideosEnum.death}.mp3`,
      videoRef: createRef(),
      audioRef: createRef(),
    },
    // [VideosEnum.recharge]: {
    //   videoSrc: `${CDN_URL}/bosses/${BossEnum.rocket}/${VideosEnum.recharge}`,
    //   videoRef: createRef(),
    // },
    bg: `${CDN_URL}/bosses/${BossEnum.rocket}/bg.png`,
    bgMobile: `${CDN_URL}/bosses/${BossEnum.rocket}/bg-mobile.png`,
  },
  [BossEnum.cleaver]: {
    [VideosEnum.idle]: {
      videoSrc: `${CDN_URL}/bosses/${BossEnum.cleaver}/${VideosEnum.idle}`,
      videoRef: createRef(),
    },
    [VideosEnum.hit]: {
      videoSrc: `${CDN_URL}/bosses/${BossEnum.cleaver}/${VideosEnum.hit}`,
      audioSrc: `${CDN_URL}/bosses/${BossEnum.cleaver}/${VideosEnum.hit}.mp3`,
      videoRef: createRef(),
      audioRef: createRef(),
    },
    [VideosEnum.death]: {
      videoSrc: `${CDN_URL}/bosses/${BossEnum.cleaver}/${VideosEnum.death}`,
      audioSrc: `${CDN_URL}/bosses/${BossEnum.cleaver}/${VideosEnum.death}.mp3`,
      videoRef: createRef(),
      audioRef: createRef(),
    },
    // [VideosEnum.recharge]: {
    //   videoSrc: `${CDN_URL}/bosses/${BossEnum.cleaver}/${VideosEnum.recharge}`,
    //   videoRef: createRef(),
    // },
    bg: `${CDN_URL}/bosses/${BossEnum.cleaver}/bg.png`,
    bgMobile: `${CDN_URL}/bosses/${BossEnum.cleaver}/bg-mobile.png`,
  },
  [BossEnum.blaster]: {
    [VideosEnum.idle]: {
      videoSrc: `${CDN_URL}/bosses/${BossEnum.blaster}/${VideosEnum.idle}`,
      videoRef: createRef(),
    },
    [VideosEnum.hit]: {
      videoSrc: `${CDN_URL}/bosses/${BossEnum.blaster}/${VideosEnum.hit}`,
      audioSrc: `${CDN_URL}/bosses/${BossEnum.blaster}/${VideosEnum.hit}.mp3`,
      videoRef: createRef(),
      audioRef: createRef(),
    },
    [VideosEnum.death]: {
      videoSrc: `${CDN_URL}/bosses/${BossEnum.blaster}/${VideosEnum.death}`,
      audioSrc: `${CDN_URL}/bosses/${BossEnum.blaster}/${VideosEnum.death}.mp3`,
      videoRef: createRef(),
      audioRef: createRef(),
    },
    // [VideosEnum.recharge]: {
    //   videoSrc: `${CDN_URL}/bosses/${BossEnum.blaster}/${VideosEnum.recharge}`,
    //   videoRef: createRef(),
    // },
    bg: `${CDN_URL}/bosses/${BossEnum.blaster}/bg.png`,
    bgMobile: `${CDN_URL}/bosses/${BossEnum.blaster}/bg-mobile.png`,
  },
  [BossEnum.queen]: {
    [VideosEnum.idle]: {
      videoSrc: `${CDN_URL}/bosses/${BossEnum.queen}/${VideosEnum.idle}`,
      videoRef: createRef(),
    },
    [VideosEnum.hit]: {
      videoSrc: `${CDN_URL}/bosses/${BossEnum.queen}/${VideosEnum.hit}`,
      audioSrc: `${CDN_URL}/bosses/${BossEnum.queen}/${VideosEnum.hit}.mp3`,
      videoRef: createRef(),
      audioRef: createRef(),
    },
    [VideosEnum.death]: {
      videoSrc: `${CDN_URL}/bosses/${BossEnum.queen}/${VideosEnum.death}`,
      audioSrc: `${CDN_URL}/bosses/${BossEnum.queen}/${VideosEnum.death}.mp3`,
      videoRef: createRef(),
      audioRef: createRef(),
    },
    // [VideosEnum.recharge]: {
    //   videoSrc: `${CDN_URL}/bosses/${BossEnum.queen}/${VideosEnum.recharge}`,
    //   videoRef: createRef(),
    // },
    bg: `${CDN_URL}/bosses/${BossEnum.queen}/bg.png`,
    bgMobile: `${CDN_URL}/bosses/${BossEnum.queen}/bg-mobile.png`,
  },
};

export const questsAssets = {
  inactiveScreenDivider: `${CDN_URL}/assets/quests/inactive-screen-divider.webp`,
  logo: `${CDN_URL}/assets/quests/quests-logo.webp`,
};

export const storesAssets = {
  appStore: `${CDN_URL}/assets/general/stores/app-store.svg`,
  googlePlay: `${CDN_URL}/assets/general/stores/google-play-store.svg`,
};

export const essenceAssets = {
  bronze: `${CDN_URL}/assets/general/essence/bronze.webp`,
  gold: `${CDN_URL}/assets/general/essence/gold.webp`,
  primordial: `${CDN_URL}/assets/general/essence/primordial.webp`,
  silver: `${CDN_URL}/assets/general/essence/silver.webp`,
};

export const authAssets = {
  wallet: `${CDN_URL}/assets/auth/wallet-logo.webp`,
  google: `${CDN_URL}/assets/auth/google-logo.webp`,
  apple: `${CDN_URL}/assets/auth/apple-logo.webp`,
  x: `${CDN_URL}/assets/auth/x-logo.webp`,
  bg: `${CDN_URL}/assets/auth/auth-bg.webp`,
  discordLogo: `${CDN_URL}/assets/auth/discord-logo-black.webp`,
  discordLogoBlue: `${CDN_URL}/assets/auth/discord-logo-blue.webp`,
  pixionLogo: `${CDN_URL}/assets/auth/pixion-auth-logo.webp`,
};

export const navBarAssets = {
  account: `${CDN_URL}/assets/components/nav-bar/account.webp`,
  accountHover: `${CDN_URL}/assets/components/nav-bar/account-hover.webp`,
  accountImg: `${CDN_URL}/assets/components/nav-bar/account.svg`,
  accountImgHover: `${CDN_URL}/assets/components/nav-bar/account-hover.svg`,
};

export const playToAirdropAssets = {
  logo: `${CDN_URL}/assets/components/play-to-airdrop/logo-shaded.webp`,
  bg: `${CDN_URL}/assets/components/play-to-airdrop/long-banner-heroes.webp`,
  mobileBanner: `${CDN_URL}/assets/components/play-to-airdrop/mobile-banner.svg`,
};

export const icons = {
  book: `${CDN_URL}/assets/general/icons/book.svg`,
  crown: `${CDN_URL}/assets/general/icons/crown.svg`,
  doubleArrowBottom: `${CDN_URL}/assets/general/icons/double-arrow-bottom.svg`,
  mobileViewLogo: `${CDN_URL}/assets/general/icons/mobile-view-logo.webp`,
  soundEnabled: `${CDN_URL}/assets/general/icons/sound-enabled.svg`,
  soundDisabled: `${CDN_URL}/assets/general/icons/sound-disabled.svg`,
  trophy: `${CDN_URL}/assets/general/icons/trophy.svg`,
  hourglass: `${CDN_URL}/assets/general/icons/hourglass-icon.webp`,
  collapse: `${CDN_URL}/assets/general/icons/collapse.svg`,
};

export const buttons = {
  blue: {
    large: {
      normal: `${CDN_URL}/assets/general/buttons/blue/large/normal.svg`,
      hover: `${CDN_URL}/assets/general/buttons/blue/large/hover.svg`,
      clicked: `${CDN_URL}/assets/general/buttons/blue/large/clicked.svg`,
    },
    regular: {
      normal: `${CDN_URL}/assets/general/buttons/blue/regular/normal.svg`,
      hover: `${CDN_URL}/assets/general/buttons/blue/regular/hover.svg`,
      clicked: `${CDN_URL}/assets/general/buttons/blue/regular/clicked.svg`,
    },
  },
  copy: {
    normal: `${CDN_URL}/assets/general/buttons/copy/regular.svg`,
    hover: `${CDN_URL}/assets/general/buttons/copy/hover.svg`,
    clicked: `${CDN_URL}/assets/general/buttons/copy/clicked.svg`,
  },
  gray: {
    normal: `${CDN_URL}/assets/general/buttons/gray/small/regular.svg`,
    share: `${CDN_URL}/assets/general/buttons/gray/gray-btn-share.webp`,
    copy: `${CDN_URL}/assets/general/buttons/gray/gray-btn-copy.webp`,
  },
  green: {
    large: {
      normal: `${CDN_URL}/assets/general/buttons/green/large/regular.svg`,
      hover: `${CDN_URL}/assets/general/buttons/green/large/hover.svg`,
      clicked: `${CDN_URL}/assets/general/buttons/green/large/clicked.svg`,
    },
    regular: {
      normal: `${CDN_URL}/assets/general/buttons/green/regular/regular.svg`,
      hover: `${CDN_URL}/assets/general/buttons/green/regular/hover.svg`,
      clicked: `${CDN_URL}/assets/general/buttons/green/regular/clicked.svg`,
    },
    small: {
      normal: `${CDN_URL}/assets/general/buttons/green/small/regular.svg`,
      hover: `${CDN_URL}/assets/general/buttons/green/small/hover.svg`,
      clicked: `${CDN_URL}/assets/general/buttons/green/small/clicked.svg`,
    },
  },
  red: {
    normal: `${CDN_URL}/assets/general/buttons/red/regular/normal.svg`,
    hover: `${CDN_URL}/assets/general/buttons/red/regular/hover.svg`,
    clicked: `${CDN_URL}/assets/general/buttons/red/regular/clicked.svg`,
  },
  yellow: {
    large: {
      normal: `${CDN_URL}/assets/general/buttons/yellow/large/regular.svg`,
      hover: `${CDN_URL}/assets/general/buttons/yellow/large/hover.svg`,
      clicked: `${CDN_URL}/assets/general/buttons/yellow/large/clicked.svg`,
    },
    small: {
      normal: `${CDN_URL}/assets/general/buttons/yellow/small/regular.svg`,
      hover: `${CDN_URL}/assets/general/buttons/yellow/small/hover.svg`,
      clicked: `${CDN_URL}/assets/general/buttons/yellow/small/clicked.svg`,
    },
  },
  btnLeft: `${CDN_URL}/assets/general/buttons/button-left.webp`,
  btnRight: `${CDN_URL}/assets/general/buttons/button-right.webp`,
  signIn: `${CDN_URL}/assets/general/buttons/sign-in-button.webp`,
  signUp: `${CDN_URL}/assets/general/buttons/sign-up-button.webp`,
};

export const generalAssets = {
  backgrounds: {
    castleBg: `${CDN_URL}/assets/general/backgrounds/castle-bg.webp`,
    ultimateBg: `${CDN_URL}/assets/general/backgrounds/ultimate-bg.webp`,
  },
  faqDivider: `${CDN_URL}/assets/general/fag-bottom.webp`,
  mainLogo: `${CDN_URL}/assets/general/main-logo.webp`,
  spinner: `${CDN_URL}/assets/general/spinner.gif`,
};

export const dashboardAssets = {
  cancelBtn: `${CDN_URL}/assets/dashboard/buttons/cancel-btn.svg`,
  greenBtn: `${CDN_URL}/assets/dashboard/buttons/green-button.webp`,
  greyBtn: `${CDN_URL}/assets/dashboard/buttons/grey-button.webp`,
  redBtn: `${CDN_URL}/assets/dashboard/buttons/red-button.webp`,
  wrongNetworkBtn: `${CDN_URL}/assets/dashboard/wrong-network-btn.svg`,
};

export const eligibilityAssets = {
  greyBannerBottom: `${CDN_URL}/assets/dashboard/eligibility-checker/eligibilty-grey-banner-bottom.webp`,
  greyBannerTop: `${CDN_URL}/assets/dashboard/eligibility-checker/eligibilty-grey-banner-top.webp`,
  yellowBanner: `${CDN_URL}/assets/dashboard/eligibility-checker/eligibilty-yellow-banner.webp`,
  timeBg: `${CDN_URL}/assets/dashboard/eligibility-checker/time-bg.webp`,
};

export const forgePageAssets = {
  forgeCreateBg: `${CDN_URL}/assets/forge/forge-create-bg.webp`,
  forgeVideoThumb: `${CDN_URL}/assets/forge/forge-thumb.webp`,
  forgeVideoSectionBg: `${CDN_URL}/assets/forge/forge-video-section-bg.webp`,
  forgeWorkshopLogo: `${CDN_URL}/assets/forge/forge-workschop-logo.webp`,
  shamanImg: `${CDN_URL}/assets/forge/shaman.webp`,
  forgeLogo: `${CDN_URL}/assets/forge/forge-logo.webp`,
};

export const homePageAssets = {
  aboutBg: `${CDN_URL}/assets/home-page/about/about-background.webp`,
  moreInfoBtn: `${CDN_URL}/assets/home-page/about/more-info-button.webp`,

  pixionLogo: `${CDN_URL}/assets/home-page/footer/pixion-logo.svg`,

  glint: `${CDN_URL}/assets/home-page/nft/glint.webp`,
  nftBg: `${CDN_URL}/assets/home-page/nft/nft-background.webp`,

  signUpBg: `${CDN_URL}/assets/home-page/sign-up/sign-up-background.webp`,
  signUpForeground: `${CDN_URL}/assets/home-page/sign-up/sign-up-foreground.webp`,

  virtuesBg: `${CDN_URL}/assets/home-page/virtues/virtues-background.webp`,
  virtuesForeground: `${CDN_URL}/assets/home-page/virtues/virtues-foreground.webp`,

  trailer: {
    bg: `${CDN_URL}/assets/home-page/trailer/trailer-background.webp`,
    thumbnail: `${CDN_URL}/assets/home-page/trailer/trailer-thumbnail.svg`,
    play: `${CDN_URL}/assets/home-page/trailer/play.webp`,
    playHover: `${CDN_URL}/assets/home-page/trailer/play-hover.webp`,
    fablebornIcon: `${CDN_URL}/assets/home-page/trailer/fableborn-icon.webp`,
  },

  clouds: `${CDN_URL}/assets/home-page/clouds.webp`,
};

export const chests = {
  closed: {
    excellent: `${CDN_URL}/assets/referrals/chests/close/excellent.svg`,
    gold: `${CDN_URL}/assets/referrals/chests/close/gold.svg`,
    good: `${CDN_URL}/assets/referrals/chests/close/good.svg`,
    great: `${CDN_URL}/assets/referrals/chests/close/great.svg`,
    normal: `${CDN_URL}/assets/referrals/chests/close/normal.svg`,
  },
  highlighted: {
    mobile: {
      excellent: `${CDN_URL}/assets/referrals/chests/mobile/highlight/excellent.svg`,
      gold: `${CDN_URL}/assets/referrals/chests/mobile/highlight/gold.svg`,
      good: `${CDN_URL}/assets/referrals/chests/mobile/highlight/good.svg`,
      great: `${CDN_URL}/assets/referrals/chests/mobile/highlight/great.svg`,
      normal: `${CDN_URL}/assets/referrals/chests/mobile/highlight/normal.svg`,
    },
    excellent: `${CDN_URL}/assets/referrals/chests/highlight/excellent.svg`,
    gold: `${CDN_URL}/assets/referrals/chests/highlight/gold.svg`,
    good: `${CDN_URL}/assets/referrals/chests/highlight/good.svg`,
    great: `${CDN_URL}/assets/referrals/chests/highlight/great.svg`,
    normal: `${CDN_URL}/assets/referrals/chests/highlight/normal.svg`,
  },
  opened: {
    mobile: {
      excellent: `${CDN_URL}/assets/referrals/chests/mobile/open/excellent.svg`,
      gold: `${CDN_URL}/assets/referrals/chests/mobile/open/gold.svg`,
      good: `${CDN_URL}/assets/referrals/chests/mobile/open/good.svg`,
      great: `${CDN_URL}/assets/referrals/chests/mobile/open/great.svg`,
      normal: `${CDN_URL}/assets/referrals/chests/mobile/open/normal.svg`,
    },
    excellent: `${CDN_URL}/assets/referrals/chests/open/excellent.svg`,
    gold: `${CDN_URL}/assets/referrals/chests/open/gold.svg`,
    good: `${CDN_URL}/assets/referrals/chests/open/good.svg`,
    great: `${CDN_URL}/assets/referrals/chests/open/great.svg`,
    normal: `${CDN_URL}/assets/referrals/chests/open/normal.svg`,
  },
};
