import '../../styles/trailer.scss';
import { AnimationOnScroll } from 'react-animation-on-scroll';

function ForgeBio() {
  return (
    <div className="relative flex h-[500px] flex-col items-center justify-center bg-trailerBg bg-center bg-no-repeat">
      <h1 className="w-full text-center font-skolar text-4xl uppercase text-darker_font_blue sm:text-5xl md:text-6xl lg:text-7xl" style={{ zIndex: '120' }} id="about">
        <AnimationOnScroll animateIn="animate__fadeIn" animateOnce={true}>
          Play. Share. Win.
        </AnimationOnScroll>
      </h1>

      <div className='m-4 text-2xl font-bold text-darker_font_blue sm:text-3xl md:text-4xl lg:text-5xl'>
        <AnimationOnScroll
          animateIn="animate__fadeIn"
          animateOnce={true}
          className=" w-full text-center"
        >
          Community Fuels The Forge
        </AnimationOnScroll>
      </div>
    </div>
  );
}

export default ForgeBio;
