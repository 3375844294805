import React, { useEffect, useState } from 'react';
import { homePageAssets, forgePageAssets } from '../../constants/assets';

function VideoPlayer({ videoLink }) {
  const [ss, setSs] = useState('');
  const [embedLink, setEmbedLink] = useState('');

  const forgePath = window.location.pathname;

  useEffect(() => {
    if (forgePath === '/forge') {
      setSs(forgePageAssets.forgeVideoThumb);
    } else {
      setSs(homePageAssets.trailer.thumbnail);
    }
    setEmbedLink(videoLink);
  }, [homePageAssets.trailer.thumbnail]);

  const videoId = embedLink.split('v=').at(1);

  const playVideo = (e) => {
    e.preventDefault();
    const youtube = document.getElementById('trailerFrame');
    const playerIcon = document.getElementById('playerIcon');
    playerIcon.style.visibility = 'hidden';
    youtube.style.display = 'block';
    youtube.src += '?autoplay=1';
  };

  return (
    <div
      className="videoplayer"
      style={{ background: `url(${ss}) top center no-repeat` }}
    >
      <img
        src={ss}
        style={{ opacity: '0', width: '100%', height: 'auto' }}
        alt="trailer"
        width="1920"
        height="1080"
      />
      <div className="player" onClick={playVideo} id="playerIcon" />
      <div className="player-container">
        <iframe
          id="trailerFrame"
          src={`https://www.youtube.com/embed/${videoId}`}
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            display: 'none',
            border: '0px',
          }}
        ></iframe>
      </div>
    </div>
  );
}

export default VideoPlayer;
