import '../../styles/forge/trailer.scss';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import Image from '../general/Image';
import VideoPlayer from '../general/VideoPlayer';
import { homePageAssets, forgePageAssets } from '../../constants/assets';

function VideoSection({ sectionRef }) {
  const videoLink = 'https://www.youtube.com/watch?v=tfLjVIOG9zA';
  const externalVideo = videoLink || false;

  const avatarIamge = homePageAssets.trailer.fablebornIcon ? (
    <Image src={homePageAssets.trailer.fablebornIcon} alt="avatar" style={{ width: '100%' }} />
  ) : (
    ''
  );

  const video = externalVideo ? (
    <VideoPlayer videoLink={videoLink} />
  ) : (
    avatarIamge
  );

  return (
    <div
      className="trailer"
      style={{
        background: `url(${forgePageAssets.forgeVideoSectionBg}) center no-repeat`,
      }}
      ref={sectionRef}
    >
      <div className='trailer-logo'>
        <img src={forgePageAssets.forgeWorkshopLogo} alt="logo" />
      </div>
      <h1 className="trailer-title" style={{ zIndex: '120' }} id="about">
        <AnimationOnScroll animateIn="animate__fadeIn" animateOnce={true}>
          DESIGN IT. PLAY IT.
        </AnimationOnScroll>
      </h1>

      <AnimationOnScroll
        animateIn="animate__fadeIn"
        animateOnce={true}
        className="avatar"
      >
        {video}
      </AnimationOnScroll>
    </div>
  );
}

export default VideoSection;
