import React, { useRef } from 'react';
import FooterMenu from '../components/general/FooterMenu';
import Footer from '../components/general/Footer';
import { useAuthRequired } from '../hooks/useAuthRequired';
import { useContent } from '../services/api/content';
import { websiteContent } from '../constants/content';
import Spinner from '../components/general/Spinner';
import AboutForge from '../components/forge/AboutForge';
import InfoForge from '../components/forge/InfoForge';
import ForgeBio from '../components/forge/ForgeBio';
import ForgeLanding from '../components/forge/ForgeLanding';
import ForgeSocial from '../components/forge/ForgeSocial';
import VideoSection from '../components/forge/VideoSection';
import ForgeCreate from '../components/forge/ForgeCreate';

const sectionsUrls = [
  websiteContent.aboutForge,
  websiteContent.footer,
  websiteContent.social,
  websiteContent.infoForge,
  websiteContent.create,
];

function Forge() {
  useAuthRequired({ strict: false });
  const sectionRef = useRef(null);

  const {
    isLoading, data: [
      aboutForge,
      footer,
      social,
      infoForge,
      create,
    ],
  } = useContent(sectionsUrls);

  if (isLoading) {
    return (
      <Spinner />
    );
  }

  return (
    <div className="relative top-[60px] m-auto block max-w-[1920px] overflow-hidden border-0 p-0">
      <div className="relative" id="home">
        <ForgeLanding sectionRef={sectionRef} />
        <ForgeBio />
        <AboutForge data={aboutForge} />
        <InfoForge data={infoForge} />
        <VideoSection sectionRef={sectionRef} />
        <ForgeCreate data={create} />
        <ForgeSocial data={social} />
        <FooterMenu data={footer} />
        <Footer data={footer} />
      </div>
    </div>
  );
}

export default Forge;
