import '../../styles/signUp.scss';
import { ReactBasicScroll } from 'react-basic-scroll';
import { useEffect } from 'react';
import forgeBgImg from '../../assets/forge-bg-img.webp';
import { forgePageAssets } from '../../constants/assets';

// import { GooglePlayBtn, AppStoreBtn } from '../quests/buttons';

function ForgeLanding({ sectionRef }) {
  // const googlePlayUrl = 'https://bit.ly/3WRojmb';
  // const appleStoreUrl = 'https://apple.co/46WpXHN';

  const handleScrollToSection = (prop) => {
    prop.current.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    setTimeout(() => {
      const fableborneLogo = document.getElementById('fableborne-forge-logo');
      fableborneLogo.classList.add('fade-in-2', '!opacity-100');
    }, 300);
  }, []);

  const bg = {
    from: 0,
    to: 1500,
    props: {
      '--bgY': {
        from: '0px',
        to: '100px',
      },
    },
  };

  const config = {
    from: 0,
    to: 1500,
    props: {
      '--translateY': {
        from: '0',
        to: '50px',
      },
      '--scale': {
        from: '1',
        to: '1.5',
      },
      '--opacity': {
        from: 0,
        to: 1,
      },
    },
  };

  return (
    <div className="sign-up relative top-0 flex justify-center" id="signup">
      <ReactBasicScroll config={bg}>
        <img
          src={forgeBgImg}
          alt="fakebg"
          className="sign-up-fake-bg sign-scene relative inset-x-0 top-0 z-0 m-auto !h-[550px] w-full max-w-none sm:!h-auto"
          style={{
            transform: 'translateY(var(--bgY))',
            willChange: 'transform',
          }}
          width="1920"
          height="1080"
        />
      </ReactBasicScroll>

      <div className="absolute inset-x-0 top-[14%] z-20 m-auto flex w-[70%] flex-col items-center justify-center text-center">
        <ReactBasicScroll config={config}>
          <img
            src={forgePageAssets.forgeLogo}
            alt="fableborne-logo"
            className="logo relative z-[300] h-auto w-full cursor-pointer opacity-0"
            data-modifier="10"
            id="fableborne-forge-logo"
            style={{
              transform: 'translateY(var(--translateY))',
              willChange: 'transform',
            }}
            width="1258"
            height="205"
            onClick={() => handleScrollToSection(sectionRef)}
          />
        </ReactBasicScroll>

        {/* <div className='mt-5 flex w-[460px] justify-center gap-2'>
            <GooglePlayBtn url={googlePlayUrl} />
            <AppStoreBtn url={appleStoreUrl} />
          </div> */}
      </div>
    </div>
  );
}

export default ForgeLanding;
